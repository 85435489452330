
import { defineComponent } from 'vue'
import { useRouter } from 'vue-router'
import { useToast } from 'primevue/usetoast.js'
import PatientForm from '@/components/PatientForm.vue'
import { getNewKey } from '@/helpers/firebase.ts'
import { db } from '@/services/firebase.ts'
import { getUTC } from '@/helpers/date.ts'
import { Patient } from '@/types.ts'
import BaseBreadcrumb from '@/components/BaseBreadcrumb.vue'

export default defineComponent({
  components: { PatientForm, BaseBreadcrumb },
  setup() {
    const toast = useToast()
    const router = useRouter()
    const breadcrumbs = [
      { label: 'Patients', to: '/patients' },
      { label: 'Create' }
    ]

    function create(formData: Patient) {
      const utc = getUTC(new Date())
      formData.created = utc
      formData.modified = utc
      formData.active = true

      const newKey = getNewKey('patients')
      db.ref('patients/' + newKey)
        .set(formData)
        .then(() => {
          router.push({ name: 'PatientIndex' })
        })
        .catch(err => {
          toast.add({
            severity: 'error',
            summary: 'Error creating patient',
            detail: err.message,
            life: 3000
          })
        })
    }

    return { create, breadcrumbs }
  }
})
